import "./footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationPin, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faDiscord, faTelegram, faYoutube} from "@fortawesome/free-brands-svg-icons";

export const Footer = () => {
  const telegramClick = () => {
    const telegramUrl = 'https://t.me/agelessrepublic'; // Replace with your Telegram URL
    window.open(telegramUrl, '_blank'); // Opens the Telegram URL in a new tab
  };
  return (
    <div className="footer">
      <img src="./images/logo.svg" alt="" className="logo" />
      <div className="info">
        
        {/* <div className="phoneEmail">
            <span>
            <FontAwesomeIcon icon={faPhone} />
            +1-543-123-456
            </span>
            <span>
            <FontAwesomeIcon icon={faEnvelope} />
            example@agelessrepublic.com
            </span>
        </div> */}
        <div className="socialMedia">
          <button ><FontAwesomeIcon icon={faTwitter} /></button>
          <button ><FontAwesomeIcon icon={faDiscord} /></button>
          <button ><FontAwesomeIcon icon={faYoutube} /></button>
          <button onClick={telegramClick}><FontAwesomeIcon icon={faTelegram} /></button>
        </div>
      </div>
    </div>
  );
};
