import './testimonial.scss'
export const Testimonial = ({testimonial}) => {
    console.log(testimonial)
  return (
    <div className='testimonial'>
      <h1>{testimonial.name}</h1>
        <p>{testimonial.testimonialText}</p>
        <div className="person">
            <img src={testimonial.img} alt={testimonial.name} />
            
        </div>
    </div>
  )
}
