import "./introcraft.scss";

const IntroCraft = () => {
  return (
    <div className="introcraft">
      <div className="wrappercraft">
        <img src="./images/gather.png" alt="intro illustration" />
        <div className="explaincraft">
          <h1>Gather resources like cotton, oak, and pelts to create powerful weapons and unique items.</h1>
        </div>
      </div>
      <div className="wrappercraft">
        <img src="./images/fight.png" alt="intro illustration" />
        <div className="explaincraft">
          <h1>Fight monsters to earn rare rewards and special treasures.</h1>
        </div>
      </div>
      <div className="wrappercraft">
        <img src="./images/gold.png" alt="intro illustration" />
        <div className="explaincraft">
          <h1> Trade anything you craft with players worldwide using the HandCash blockchain wallet.  </h1>
        </div>
      </div>
    </div>
  );
};

export default IntroCraft;