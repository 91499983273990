import './app.scss'
import { Header } from './components/header/Header';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Footer } from './components/footer/Footer';
import Home from './pages/Home';
function App() {
  return (
    // <div className="App">
    //   <Header/>
    //   <Intro/>
    //   <Badges/>
    //   <StayProductive/>
    //   <Testimonials/>
    //   <EarlyAccess/>
    //   <Footer/>
    // </div>
    <BrowserRouter>
      <Header/>
      <Routes>
        <Route path="/" index element={<Home />} />
        {/* <Route path="/leaderboard" element={<Blogs />} />
        <Route path="/gameItems" element={<Contact />} />
        <Route path="/gameSkills" element={<Contact />} />
        <Route path="/gameMap" element={<Contact />} /> */}
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
