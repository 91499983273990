import React from 'react';
import { Intro, IntroCraft, Testimonials } from './components';


const Home = () => {
    return (
        <div>
            <Intro/>
            <Testimonials/>
            <IntroCraft/>
        </div>
    )
}

export default Home;